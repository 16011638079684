<div class="boletas-container">
    <!-- Título y subtítulo -->
    <app-page-header
      [title]="'Vacaciones'"
      [subtitle]="'Administra y genera las vacaciones de los empleados'"
    ></app-page-header>
  
    <!-- Botones, combo y barra de búsqueda -->
    <app-page-acciones
      [buttonText]="'Crear vacaciones'"
      [buttonIcon]="'pi pi-plus'"
      [buttonColor]="'#04a563'"
      (buttonClick)="onNewRegister()"
      [comboOptions]="monthOptions"
      [comboSelected]="currentMonth"
      (comboSelectedChange)="onMonthChange($event)"
      [searchQuery]="searchTerm"
      (searchQueryChange)="onSearch($event)"
    >
    </app-page-acciones>
  
    <!-- Tabs y tabla dinámica -->
    <app-page-container
      [tabs]="tabs"
      [displayedColumns]="displayedColumns"
      [dataSource]="dataSource"
      [actionButtons]="actionButtons"
      [headerClass]="currentHeaderClass"
      [showPreviewCard]="false"
      [isLoading]="isLoading"
      [customTemplates]="{
        persona: personaTemplate
      }"
      (tabSelected)="onTabSelected($event)"
      (rowSelected)="onRowSelected($event)"
      [selectedDocument]="selectedDocument"
      [hasCheckbox]="true"
    >
      <ng-template #personaTemplate let-row>
        {{ row.persona | capitalize }}
      </ng-template>
    </app-page-container>
  
    <!-- Modal del registro -->
    <app-vacation-register-modal
      [(visible)]="mostrar"
      (visibleChange)="onDialogClose()"
      (registerComplete)="onRegisterComplete()"
    ></app-vacation-register-modal>
  
    <app-vacation-edit-modal
      [(visible)]="mostrarModalEditar"
      [dataToEdit]="dataToEdit"
      [id]="vacationId"
      (visibleChange)="onDialogClose()"
      (editComplete)="onEditComplete()"
    ></app-vacation-edit-modal>
  
    <app-vacation-detail-modal
      [(visible)]="mostrarModalDetalle"
      [dataToView]="dataDetail"
      (visibleChange)="onDialogDetailClose()"
    ></app-vacation-detail-modal>
  </div>
  