import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SolicitudeRoutingModule } from './solicitude-routing.module';
import { SolicitudeComponent } from './solicitude.component';
import { UpdateStateModalComponent } from './components/update-state-modal/update-state-modal.component';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [SolicitudeComponent, UpdateStateModalComponent],
  imports: [
    SolicitudeRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    CalendarModule,
    InputSwitchModule,
    CheckboxModule
  ],
  providers: [DialogService],
})
export class SolicitudeModule {}
