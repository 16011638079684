<div class="boletas-container">
  <!-- Título y subtítulo -->
  <app-page-header
    [title]="'Solicitudes'"
    [subtitle]="'Administra tus solicitudes'"
  ></app-page-header>

  <!-- Botones, combo y barra de búsqueda -->
  <app-page-acciones
    [hasButton]="false"
    [comboOptions]="monthOptions"
    [comboSelected]="currentMonth"
    (comboSelectedChange)="onMonthChange($event)"
    [searchQuery]="searchTerm"
    (searchQueryChange)="onSearch($event)"
  >
  </app-page-acciones>

  <!-- Tabs y tabla dinámica -->
  <app-page-container
    [tabs]="tabs"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [actionButtons]="actionButtons"
    [headerClass]="currentHeaderClass"
    [showPreviewCard]="false"
    [isLoading]="isLoading"
    [customTemplates]="{
      ultimaActualizacion: ultimaActualizacionTemplate,
      persona: personaTemplate
    }"
    (tabSelected)="onTabSelected($event)"
    (rowSelected)="onRowSelected($event)"
    [selectedDocument]="selectedDocument"
    [hasCheckbox]="true"
  >
    <ng-template #ultimaActualizacionTemplate let-row>
      {{ row.ultimaActualizacion || "No hay actualización" }}
    </ng-template>
    <ng-template #personaTemplate let-row>
      {{ row.persona | capitalize }}
    </ng-template>
  </app-page-container>

  <!-- Modal del detalle -->
  <app-update-state-modal
    [(visible)]="viewVacation"
    [dataToView]="dataDetail"
    (visibleChange)="onDialogDetailClose()"
    (updateStateComplete)="onUpdateStateComplete()"
  >
  </app-update-state-modal>

  <app-modal-deleted
    [(visible)]="viewDeleted"
    [id]="solicitudeId"
    (visibleChange)="onDialogDetailClose()"
    [buttonAction] = "onButtonConfirmedClick.bind(this)"
  ></app-modal-deleted>
</div>
