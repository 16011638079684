import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CurrencyService } from '../../../services/currency.service';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
import { PaymentSlipService } from '../../services/payment-slip.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';

@Component({
  selector: 'app-registro-modal',
  templateUrl: './registro-modal.component.html',
  styleUrls: ['./registro-modal.component.scss'],
  providers: [DialogService],
})
export class RegistroModalComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() registerComplete = new EventEmitter<void>();

  constructor(
    private paymentSlipService: PaymentSlipService,
    private currencyService: CurrencyService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private dialogService: DialogService
  ) {}

  // Propiedades para los combos
  selectedEmpresa: IComboBase | null = null;
  selectedColaborador: IComboBase | null = null;
  selectedMoneda: IComboBase | null = null;
  monto: string | null = null;
  currentFile: File | null = null;
  issueDate: Date | null = null;
  today: Date = new Date();
  
  filteredOptions: IComboBase[] = [];
  empresas: IComboBase[] = [];
  colaboradores: IComboBase[] = [];
  monedas: IComboBase[] = [];

  companyId: string | null = null;

  isDisabled: boolean = true;

  @ViewChild('fileUploader') fileUploader: any;
  selectedFileName: string | null = null;

  hasInteracted: { [key: string]: boolean } = {
    empresa: false,
    colaborador: false,
    moneda: false,
    monto: false,
    documento: false,
    fecha: false,
  };

  validateForm() {
    const isMontoValid = this.monto !== null && Number(this.monto) > 0;
    this.isDisabled = !(
      this.selectedEmpresa &&
      this.selectedColaborador &&
      this.selectedMoneda &&
      isMontoValid &&
      this.issueDate &&
      this.currentFile
    );

    console.log('VALIDACION: ', this.isDisabled);
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'empresa':
        options = this.empresas;
        break;
      case 'colaborador':
        options = this.colaboradores;
        break;
      case 'moneda':
        options = this.monedas;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'empresa':
        this.selectedEmpresa = event.value ?? null;
        console.log('Empresa seleccionada:', this.selectedEmpresa);
        this.companyId = this.selectedEmpresa?.key!;

        this.selectedColaborador = null;

        if (this.companyId) {
          this.employeeService.comboEmployee(this.companyId).subscribe({
            next: (data) => {
              this.colaboradores = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }

        break;
      case 'colaborador':
        this.selectedColaborador = event.value ?? null;
        console.log('Colaborador seleccionado:', this.selectedColaborador);
        break;
      case 'moneda':
        this.selectedMoneda = event.value ?? null;
        console.log('Moneda seleccionada:', this.selectedMoneda);
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateForm();
  }

  // Manejar el cambio de archivo
  openFileUpload() {
    this.fileUploader.choose();
  }

  onChangeFile(event: any) {
    const selectedFile = event.files[0];
    if (selectedFile) {
      this.currentFile = selectedFile;
      this.selectedFileName = event.files[0].name;
      this.hasInteracted['documento'] = true;
    }
    this.validateForm();
  }

  onRegister() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const request = {
      empresaId: this.selectedEmpresa?.key,
      colaboradorId: this.selectedColaborador?.key,
      monedaId: this.selectedMoneda?.key,
      monto: this.monto,
      archivo: this.currentFile,
      fecha: this.issueDate?.toISOString().replace('Z', ''),
    };

    const formData = new FormData();

    // Agregar los datos adicionales
    formData.append('companyId', request.empresaId!);
    formData.append('employeeId', request.colaboradorId!);
    formData.append('paymentDocumentNumber', 'DOC-PRUEBA-01');
    formData.append('currencyId', request.monedaId!);
    formData.append('paymentSlipAmount', request.monto!);
    formData.append('issueDate', request.fecha!);

    formData.append('file', this.currentFile!);

    // Llamar al servicio para registrar el recibo de pago
    this.paymentSlipService.createPaymentSlip(formData).subscribe({
      next: (response) => {
        loadingRef.close();
        console.log('Recibo de pago registrado con éxito:', response);
        const ref = this.dialogService.open(ModalSuccessComponent, {
          header: '',
          data: {
            text: 'Tu registro ha sido realizado con éxito.',
            title: '¡Éxito!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.registerComplete.emit(); 
        this.closeDialog();
      },
      error: (err) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: err.error?.error || 'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
            title: '¡Lo sentimos!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        console.error('Error al registrar el recibo de pago:', err);
      },
    });
    this.closeDialog();
  }

  onCancel() {
    this.resetForm();
    this.closeDialog();
  }

  // Limpiar los campos del modal
  resetForm() {
    this.selectedEmpresa = null;
    this.selectedColaborador = null;
    this.selectedMoneda = null;
    this.monto = null;
    this.issueDate = null;
    this.currentFile = null;
    this.selectedFileName = null;

    this.hasInteracted = {
      empresa: false,
      colaborador: false,
      moneda: false,
      monto: false,
      documento: false,
      fecha: false,
    };

    if (this.fileUploader) {
      this.fileUploader.clear();
    }

    this.validateForm();
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.resetForm();
  }

  ngOnInit(): void {
    this.resetForm();

    this.currencyService.comboCurrency().subscribe({
      next: (data) => {
        this.monedas = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de monedas:', err);
      },
    });

    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.empresas = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'empresa':
        this.selectedEmpresa = null;
        console.log('EMPRESA: ', this.selectedEmpresa);
        break;
      case 'colaborador':
        this.selectedColaborador = null;
        console.log('COLABORADOR: ', this.selectedColaborador);
        break;
      case 'moneda':
        this.selectedMoneda = null;
        console.log('MONEDA: ', this.selectedMoneda);
        break;
      case 'monto':
        this.monto = event;
        console.log('MONTO: ', this.monto);
        break;
      case 'fecha':
        this.issueDate = event;
        console.log('FECHA: ', this.issueDate);
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateForm();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true; // Activar validación al perder foco
  }
}
