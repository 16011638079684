import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageAccionesComponent } from './page-acciones/page-acciones.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomMatPaginatorIntl } from './paginator/custom-mat-paginator-intl.component';
import { MatIconModule } from '@angular/material/icon';
import { ModalSuccessComponent } from './modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from './modals/modal-loading/modal-loading.component';
import { ModalRejectComponent } from './modals/modal-reject/modal-reject.component';
import { CurrencySymbolPipe } from './pipe/currency-symbol.pipe';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CapitalizePipe } from './pipe/capitalize.pipe';
import { ModalDeletedComponent } from './modals/modal-deleted/modal-deleted.component';


@NgModule({
  declarations: [
    // Componentes reutilizables
    PageHeaderComponent,
    PageAccionesComponent,
    PageContainerComponent,
    ModalSuccessComponent,
    ModalLoadingComponent,
    ModalRejectComponent,
    ModalDeletedComponent,
    CurrencySymbolPipe,
    CapitalizePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AutoCompleteModule,
    InputNumberModule,
    FileUploadModule,
    ProgressSpinnerModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RadioButtonModule,
    DialogModule
  ],
  exports: [
    // Componentes reutilizables
    PageHeaderComponent,
    PageAccionesComponent,
    PageContainerComponent,
    ModalSuccessComponent,
    ModalLoadingComponent,
    ModalRejectComponent,
    ModalDeletedComponent,
    CurrencySymbolPipe,
    CapitalizePipe,
    // Módulos necesarios
    CommonModule,
    FormsModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AutoCompleteModule,
    DialogModule,
    InputNumberModule,
    FileUploadModule,
    ProgressSpinnerModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ]
})
export class SharedModule {}
