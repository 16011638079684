import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CurrencyService } from '../../../services/currency.service';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
import { PaymentSlipService } from '../../services/payment-slip.service';
import { forkJoin } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
  providers: [DialogService],
})
export class EditModalComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToEdit: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() editComplete = new EventEmitter<void>();
  @Output() deleteComplete = new EventEmitter<void>();

  constructor(
    private paymentSlipService: PaymentSlipService,
    private currencyService: CurrencyService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  paymentSlipId: number = 0;
  isLoading: boolean = false;
  // Propiedades para los combos
  selectedEmpresa: IComboBase | null = null;
  selectedColaborador: IComboBase | null = null;
  selectedMoneda: IComboBase | null = null;
  monto: string | null = null;
  currentFile: File | null = null;
  issueDate: Date | null = null;
  today: Date = new Date();
  isPaid: boolean = false;

  filteredOptions: IComboBase[] = [];
  empresas: IComboBase[] = [];
  colaboradores: IComboBase[] = [];
  monedas: IComboBase[] = [];

  companyId: string | null = null;

  isDisabled: boolean = true;

  @ViewChild('fileUploader') fileUploader: any;
  selectedFileName: string | null = null;

  hasInteracted: { [key: string]: boolean } = {
    empresa: false,
    colaborador: false,
    moneda: false,
    monto: false,
    documento: false,
    fecha: false,
  };

  validateForm() {
    const isMontoValid = this.monto !== null && Number(this.monto) > 0;
    this.isDisabled = !(
      this.selectedEmpresa &&
      this.selectedColaborador &&
      this.selectedMoneda &&
      isMontoValid &&
      this.selectedFileName &&
      this.issueDate !== null
    );
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'empresa':
        options = this.empresas;
        break;
      case 'colaborador':
        options = this.colaboradores;
        break;
      case 'moneda':
        options = this.monedas;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'empresa':
        this.selectedEmpresa = event.value ?? null;
        this.companyId = this.selectedEmpresa?.key!;

        this.selectedColaborador = null;

        if (this.companyId) {
          this.employeeService.comboEmployee(this.companyId).subscribe({
            next: (data) => {
              this.colaboradores = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }

        break;
      case 'colaborador':
        this.selectedColaborador = event.value ?? null;
        break;
      case 'moneda':
        this.selectedMoneda = event.value ?? null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateForm();
  }

  // Manejar el cambio de archivo
  openFileUpload() {
    this.fileUploader.choose();
  }

  onChangeFile(event: any) {
    const selectedFile = event.files[0];
    if (selectedFile) {
      this.currentFile = selectedFile;
      this.selectedFileName = selectedFile.name;
      this.hasInteracted['documento'] = true;
    }
    this.validateForm();
  }

  onEdit() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const request = {
      empresaId: this.selectedEmpresa?.key,
      colaboradorId: this.selectedColaborador?.key,
      monedaId: this.selectedMoneda?.key,
      monto: this.monto,
      archivo: this.currentFile,
      issueDate: this.issueDate?.toISOString().replace('Z', ''),
      isPaid: this.isPaid,
    };

    const formData = new FormData();

    // Agregar los datos adicionales
    formData.append('companyId', request.empresaId!);
    formData.append('employeeId', request.colaboradorId!);
    formData.append('paymentDocumentNumber', 'DOC-PRUEBA-01');
    formData.append('currencyId', request.monedaId!);
    formData.append('paymentSlipAmount', request.monto!);

    // Convertir la fecha a string si no es null
    if (request.issueDate) {
      const formattedDate = request.issueDate;
      formData.append('issueDate', formattedDate);
    }

    formData.append('isPaid', String(request.isPaid));

    // formData.append('file', this.currentFile!);
    if (!this.currentFile) {
      // formData.append('file', '');
    } else {
      formData.append('file', this.currentFile!);
    }

    // Llamar al servicio para actualizar la boleta
    this.paymentSlipService
      .updatePaymentSlip(this.paymentSlipId, formData)
      .subscribe({
        next: (response) => {
          loadingRef.close();
          this.closeDialog();
          const ref = this.dialogService.open(ModalSuccessComponent, {
            closable: true,
            header: '',
            data: {
              text: 'El cambio se ha realizado exitosamente.',
              title: '¡Éxito!',
              icon: 'pi pi-check-circle',
              showButton: true,
            buttonText: 'Entendido',
            },
          });
          this.editComplete.emit();
        },
        error: (err) => {
          loadingRef.close();
          const ref = this.dialogService.open(ModalRejectComponent, {
            header: '',
            data: {
              text:
                err.error?.error ||
                'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
              title: '¡Lo sentimos!',
              showButton: true,
            buttonText: 'Entendido',
            },
          });
          console.error('Error al registrar el recibo de pago:', err);
        },
      });
    this.closeDialog();
  }

  onDelete() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      header: 'Cargando',
      closable: false,
    });
    this.paymentSlipService.deletePaymentSlip(this.paymentSlipId).subscribe({
      next: (response) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalSuccessComponent, {
          header: '',
          data: {
            text: 'El registro se ha eliminado exitosamente.',
            title: '¡Éxito!',
            icon: 'pi pi-check-circle',
          },
        });
        this.deleteComplete.emit();
        this.closeDialog();
      },
      error: (error) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'Hubo un error en su solicitud. Inténtalo nuevamente.',
            title: '¡Lo sentimos!',
          },
        });
        console.error('Error al eliminar el pago:', error);
      },
    });
    this.closeDialog();
  }

  onCancel() {
    this.closeDialog();
  }

  // Limpiar los campos del modal
  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ngOnInit(): void {
    this.currencyService.comboCurrency().subscribe({
      next: (data) => {
        this.monedas = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de monedas:', err);
      },
    });

    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.empresas = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });
  }

  ngOnChanges(): void {
    this.isLoading = true;
    if (this.dataToEdit) {
      this.paymentSlipId = this.dataToEdit.paymentSlipId;

      this.paymentSlipService.getPaymentSlipById(this.paymentSlipId).subscribe({
        next: (payment) => {
          if (!payment.companyId) {
            console.error('companyId no definido en la respuesta.');
            return;
          }

          const observables = {
            empresas: this.companyService.comboCompany(),
            monedas: this.currencyService.comboCurrency(),
            colaboradores: this.employeeService.comboEmployee(
              payment.companyId
            ),
          };

          forkJoin(observables).subscribe({
            next: ({ empresas, monedas, colaboradores }) => {
              this.empresas = empresas;
              this.monedas = monedas;
              this.colaboradores = colaboradores;

              this.selectedEmpresa =
                this.empresas.find(
                  (e) => String(e.key) === String(payment.companyId)
                ) || null;

              this.selectedColaborador =
                this.colaboradores.find(
                  (c) => String(c.key) === String(payment.employeeId)
                ) || null;

              this.selectedMoneda =
                this.monedas.find(
                  (m) => String(m.key) === String(payment.currencyId)
                ) || null;

              this.monto = payment.paymentSlipAmount || null;

              this.issueDate = payment.issueDate
                ? this.parseCustomDate(payment.issueDate)
                : null;

              this.isPaid = !!payment.isPaid;

              this.selectedFileName = payment.paymentSlipUrl
                ? this.extractFileName(payment.paymentSlipUrl)
                : null;

              if (payment.paymentSlipUrl) {
                const blob = new Blob([], { type: 'application/pdf' });
                this.currentFile = null;
              }

              this.isLoading = false;
              this.validateForm();
              this.cdr.detectChanges();
            },
            error: (err) => {
              this.isLoading = false;
              console.error('Error al cargar los datos necesarios:', err);
            },
          });
        },
        error: (err) => {
          console.error(
            'Error al obtener los detalles del recibo de pago:',
            err
          );
        },
      });
    }
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'empresa':
        this.selectedEmpresa = null;
        break;
      case 'colaborador':
        this.selectedColaborador = null;
        break;
      case 'moneda':
        this.selectedMoneda = null;
        break;
      case 'monto':
        this.monto = event;
        break;
      case 'fecha':
        this.issueDate = event;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateForm();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  parseCustomDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  }

  extractFileName(fileUrl: string): string {
    const lastDashIndex = fileUrl.lastIndexOf('-', fileUrl.lastIndexOf('.'));
    const fileName = fileUrl.substring(lastDashIndex + 1);
    return fileName;
  }
}
