import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { VacationService } from '../../services/vacation.service';

@Component({
  selector: 'app-vacation-register-modal',
  templateUrl: './vacation-register-modal.component.html',
  styleUrls: ['./vacation-register-modal.component.scss'],
  providers: [DialogService],
})
export class VacationRegisterModalComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() registerComplete = new EventEmitter<void>();

  constructor(
    private vacationService: VacationService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private dialogService: DialogService
  ) {}

  // Propiedades para los combos
  selectedEmpresa: IComboBase | null = null;
  selectedColaborador: IComboBase | null = null;

  startDate: Date | null = null;
  endDate: Date | null = null;
  minDate: Date | null = null;
  maxStartDate: Date | null = null;
  maxEndDate: Date | null = null;
  tooltipVisible: string | null = null;

  vacationTypes: IComboBase[] = [
    { key: '1', text: 'Por ley' },
    { key: '2', text: 'Bono vacacional' },
  ];
  selectedOptionKey: string | null = null;

  calculateAccumulatedDays: number = 10;
  selectedVacationType: any;
  filteredVacationTypes: IComboBase[] = [];
  totalDays: number = 0;

  transferableDays = Math.max(
    0,
    this.calculateAccumulatedDays - this.totalDays
  );

  selectedDates: Date[] = [];
  message = '';

  filteredOptions: IComboBase[] = [];
  empresas: IComboBase[] = [];
  colaboradores: IComboBase[] = [];

  companyId: string | null = null;
  employeeId: number | null = null;

  isDisabled: boolean = true;

  statusSolicitude: IComboBase[] = [
    { key: '1', text: 'Recibida' },
    { key: '2', text: 'En evaluación' },
    { key: '3', text: 'Rechazado' },
    { key: '4', text: 'Aprobado' },
  ];
  selectedstatusSolicitude: any;

  hasInteracted: { [key: string]: boolean } = {
    empresa: false,
    colaborador: false,
    tipoVacacion: false,
    endDate: false,
    startDate: false,
  };

  ngOnInit(): void {
    this.resetForm();
    this.calculateTotalDays();

    if (this.statusSolicitude.length > 0) {
      this.selectedstatusSolicitude = this.statusSolicitude[0];
    }

    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.empresas = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });
  }

  validateForm() {
    this.isDisabled = !(
      this.selectedEmpresa &&
      this.selectedColaborador &&
      this.startDate &&
      this.endDate &&
      this.selectedVacationType
    );

    console.log('VALIDACION: ', this.isDisabled);
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'empresa':
        options = this.empresas;
        break;
      case 'colaborador':
        options = this.colaboradores;
        break;
      case 'status':
        options = this.statusSolicitude;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'empresa':
        this.selectedEmpresa = event.value ?? null;
        this.companyId = this.selectedEmpresa?.key!;
        this.selectedColaborador = null;

        if (this.companyId) {
          this.employeeService.comboEmployee(this.companyId).subscribe({
            next: (data) => {
              this.colaboradores = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }

        break;
      case 'colaborador':
        this.selectedColaborador = event.value ?? null;
        this.employeeId = Number(this.selectedColaborador?.key!);
        if (this.employeeId) {
          this.employeeService.getEmployeeById(this.employeeId).subscribe({
            next: (data) => {
              this.calculateAccumulatedDays = data.accumulatedDays;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }

        break;
      case 'tipoVacacion':
        this.selectedVacationType = event.value ?? null;
        this.selectedOptionKey = this.selectedVacationType?.key || null;
        break;
      case 'status':
        this.selectedstatusSolicitude = event.value ?? null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateForm();
  }

  calculateTotalDays(): void {
    if (this.startDate && this.endDate) {
      const start = this.startDate;
      const end = this.endDate;

      const diffInDays =
        (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1;

      this.totalDays = diffInDays > 0 ? Math.round(diffInDays) : 0;
    } else {
      this.totalDays = 0;
    }

    this.transferableDays = Math.max(
      0,
      this.calculateAccumulatedDays - this.totalDays
    );
  }

  onRegister() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const solicitudeCode = `VACATION-${randomNumber}`;

    const request = {
      empresaId: this.selectedEmpresa?.key,
      colaboradorId: this.selectedColaborador?.key,
      typeVacationId: this.selectedVacationType?.key,
      solicitudeCode: solicitudeCode,
      startDate: this.startDate?.toISOString().replace('Z', ''),
      endDate: this.endDate?.toISOString().replace('Z', ''),
      detailSolicitudeMessage: '',
        detailSolicitudeDescription: this.message
        ? this.message
        : 'Se ha realizado una solicitud de vacaciones, por favor, revisarlo y confirmar.',
    };

    const formData = new FormData();

    // Agregar los datos adicionales
    formData.append('companyId', request.empresaId!);
    formData.append('employeeId', request.colaboradorId!);
    formData.append('solicitudeCode', request.solicitudeCode);
    formData.append('typeVacationId', request.typeVacationId!);
    formData.append('breakStartDate', request.startDate!);
    formData.append('breakEndDate', request.endDate!);
    formData.append(
      'detailSolicitudeMessage',
      request.detailSolicitudeMessage!
    );
    formData.append('detailSolicitudeDescription', request.detailSolicitudeDescription!);
    formData.append('detailSolicitudeId', '1');

    // Llamar al servicio para registrar el recibo de pago
    this.vacationService.createVacation(formData).subscribe({
      next: (response) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalSuccessComponent, {
          header: '',
          data: {
            text: 'Tu Solicitud ha sido realizado con éxito.',
            title: '¡Éxito!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.registerComplete.emit();
        this.closeDialog();
      },
      error: (err) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          closable: true,
          data: {
            text:
              err.error?.error ||
              'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
            title: '¡Lo sentimos!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        console.error('Error al registrar el recibo de pago:', err);
      },
    });
    this.closeDialog();
  }

  onCancel() {
    this.resetForm();
    this.closeDialog();
  }

  // Limpiar los campos del modal
  resetForm() {
    this.selectedEmpresa = null;
    this.selectedColaborador = null;
    this.selectedVacationType = null;
    this.startDate = null;
    this.endDate = null;
    this.message = '';
    this.selectedDates = [];

    this.hasInteracted = {
      empresa: false,
      colaborador: false,
      tipoVacacion: false,
      endDate: false,
      startDate: false,
    };

    // this.calculateTotalDays();
    // this.syncSelectedDates();
    this.validateForm();
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.resetForm();
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'empresa':
        this.selectedEmpresa = null;
        break;
      case 'colaborador':
        this.selectedColaborador = null;
        break;
      case 'tipoVacacion':
        this.selectedVacationType = null;
        break;
      case 'status':
        this.selectedstatusSolicitude = null;
        break;
      case 'startDate':
        this.startDate = event;
        break;
      case 'endDate':
        this.endDate = event;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateForm();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  filterVacationTypes(event: any): void {
    const query = event.query.toLowerCase();
    this.filteredVacationTypes = this.vacationTypes.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  openTooltipModal(title: string, description: string) {
    this.dialogService.open(ModalInfoComponent, {
      closable: false,
      data: {
        title: title,
        description: description,
      },
    });
  }

  onStartDateChange(selectedStartDate: Date): void {
    if (selectedStartDate) {
      this.startDate = selectedStartDate;
      this.maxEndDate = new Date(selectedStartDate);
      this.maxEndDate.setDate(this.maxEndDate.getDate() + 29);

      if (this.endDate && this.endDate < this.startDate) {
        this.endDate = null;
      }

      this.syncSelectedDates();
      this.calculateTotalDays();
    } else {
      this.maxEndDate = null;
    }
  }

  onEndDateChange(selectedEndDate: Date): void {
    if (selectedEndDate) {
      if (this.startDate && selectedEndDate < this.startDate) {
        this.endDate = null;
      } else {
        this.endDate = selectedEndDate;
      }

      this.syncSelectedDates();
      this.calculateTotalDays();
    }
  }

  onRangeSelect(): void {
    if (Array.isArray(this.selectedDates) && this.selectedDates.length === 2) {
      this.startDate = this.selectedDates[0];
      this.endDate = this.selectedDates[1];

      console.log('this.startDate on range ', this.startDate);
      console.log('this.endDate on range ', this.endDate);
      if (this.startDate) {
        this.maxEndDate = new Date(this.startDate);
        this.maxEndDate.setDate(this.maxEndDate.getDate() + 30);
      } else {
        this.maxEndDate = null;
      }
    } else {
      this.startDate = this.endDate = null;
    }
    // this.calculateTotalDays();
  }

  syncSelectedDates(): void {
    // if (this.startDate && this.endDate) {
    //   this.selectedDates = [this.startDate, this.endDate];
    // } else if (
    //   Array.isArray(this.selectedDates) &&
    //   this.selectedDates.length === 2
    // ) {
    //   this.startDate = this.selectedDates[0];
    //   this.endDate = this.selectedDates[1];
    // } else {
    //   this.selectedDates = [];
    //   this.startDate = null;
    //   this.endDate = null;
    // }
    if (this.startDate && this.endDate) {
      this.selectedDates = [this.startDate, this.endDate];
    } else {
      this.selectedDates = [];
    }
  }

  getDayLabel(days: number): string {
    return days === 1 ? 'día' : 'días';
  }
}
