import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IComboBase } from '../../interfaces/base/base.interface';

@Component({
  selector: 'app-page-acciones',
  templateUrl: './page-acciones.component.html',
  styleUrls: ['./page-acciones.component.scss'],
})
export class PageAccionesComponent {
  // Configuración para el botón
  @Input() buttonText: string = ''; // Texto del botón
  @Input() buttonIcon: string = ''; // Ícono del botón
  @Input() buttonColor: string = ''; // Color del botón
  @Output() buttonClick = new EventEmitter<void>(); // Evento de clic en el botón
  @Input() hasButton: boolean = true;

  // Configuración para el combo
  @Input() comboOptions: any[] = []; // Lista de opciones del combo
  @Input() comboSelected: any; // Valor seleccionado
  // @Input() comboPlaceholder: string = ''; // Placeholder del combo
  @Output() comboSelectedChange = new EventEmitter<any>(); // Evento de cambio en el combo

  // Configuración para la barra de búsqueda
  @Input() searchPlaceholder: string = 'Buscar...'; // Placeholder de búsqueda
  @Input() searchQuery: string = ''; // Consulta actual
  @Output() searchQueryChange = new EventEmitter<string>(); // Evento de cambio en la búsqueda

  // Métodos para manejar las acciones
  onButtonClick(): void {
    this.buttonClick.emit(); // Emitir evento cuando se hace clic en el botón
  }

  onComboChange(value: IComboBase): void {
    this.comboSelected = value;
    this.comboSelectedChange.emit(value); // Emitir evento de cambio en el combo
  }

  onSearchInput(query: string): void {
    this.searchQuery = query;
    this.searchQueryChange.emit(query); // Emitir evento de cambio en la búsqueda
  }
}
