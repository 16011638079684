import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { AuthModel } from '../models/auth.model';
import { EmployeeService } from '../../services/employee.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private apiUrl = `${environment.apiUrl}/auth/administrador/login`;
  private subscriptions: Subscription = new Subscription();

  private userSubject = new BehaviorSubject<any | null>(null);
  public user$: Observable<any | null> = this.userSubject.asObservable();

  private tokenSubject = new BehaviorSubject<string | null>(null);
  public token$: Observable<string | null> = this.tokenSubject.asObservable();

  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private employeeService: EmployeeService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
		this.isLoading$ = this.isLoadingSubject.asObservable();

    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      this.userSubject.next(userData);
      this.tokenSubject.next(userData.token);
    }
  }

  private decodeToken(token: string): any {
    return jwtDecode(token); // Usamos la función jwtDecode
  }

  private isTokenValid(decodedToken: any): boolean {
    const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    return decodedToken.exp > currentTime;
  }

  login(
    email: string,
    password: string,
    rolType: string
  ): Observable<AuthModel | null> {
    const body = { email, password, rolType };
    this.isLoadingSubject.next(true);

    return this.http.post<AuthModel>(this.apiUrl, body).pipe(
      map((response: AuthModel) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const decodedToken: any = this.decodeToken(response.token); // Deserializamos el token

        if (this.isTokenValid(decodedToken)) {
          // Validamos si el token es válido
          localStorage.setItem('access', JSON.stringify(response));
          this.userSubject.next(response); // Emitir nuevo objeto completo
          this.tokenSubject.next(response.token);
          this.messageService.add({
            severity: 'success',
            summary: 'Información',
            detail: 'Logueo exitoso',
          });
          return response;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Información',
            detail: 'El token a expirado',
          });
          return null;
        }
      }),
      catchError((error) => {
        switch (error.status) {
          case 401:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail: 'Contraseña incorrecta',
            });
            break;
          case 403:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail: 'No tienes los permisos para acceder como administrador',
            });
            break;
          case 404:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail: 'Email no encontrado',
            });
            break;
          case 500:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail:
                'Hubo un problema en el servidor. Por favor, intenta más tarde',
            });
            break;
          default:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail:
                'Ocurrió un error inesperado. Por favor, contacta al soporte técnico',
            });
        }
        return of(null);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout(): void {
    localStorage.removeItem('access');
    this.userSubject.next(null); // Limpia el estado del usuario
    this.tokenSubject.next(null); // Limpia el token
    this.clearStorage();
    this.employeeService.clearCache();
    this.messageService.add({
      severity: 'info',
      summary: 'Información',
      detail: 'Has cerrado sesión exitosamente.',
    });
  }

  clearStorage() {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
