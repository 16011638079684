import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  providers: [MessageService, DialogService],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  errorMessage: string | null = null;
  isLoading$: Observable<boolean>;
  isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.isLoading$ = this.authService.isLoading$;
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rolType: ['ADMIN', Validators.required],
    });
  }

  onLogin() {
    if (this.loginForm.valid) {
      this.isSubmitting = true;
      const { email, password, rolType } = this.loginForm.value;

      this.authService.login(email, password, rolType).subscribe({
        next: (response) => {
          if (response) {
            this.router.navigate(['/dashboard/home']); // Cambia a la ruta correspondiente
          } else {
            this.isSubmitting = false;
          }
        },
        error: (error) => {
          this.errorMessage = error.error.message;
          this.isSubmitting = false;
        },
      });
    } else {
      this.errorMessage = 'Por favor, complete todos los campos.';
    }
  }
}
