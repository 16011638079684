import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VacationService {
  private apiUrl = `${environment.apiUrl}/solicitudes`;

  constructor(private http: HttpClient) {}

  postSolicitudesByCompany(filter: { search: string }): Observable<any[]> {
    return this.http.post<any[]>(
      this.apiUrl + '/listSolicitudeByCompany',
      filter
    );
  }

  createVacation(data: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/registerSolicitudeVacation', data);
  }

  updateVacation(data: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/updateSolicitudeVacation', data);
  }
}
