import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  permissions: string[] = []; // Almacenará los permisos del usuario
  currentDate: string = '';
  userData: any = null;
  fullname: string = '';
  employeeData: any = null;
  
  menuOptions = [
    {
      label: 'Inicio',
      icon: 'pi pi-home',
      route: '/dashboard/home',
      permission: 'VIEW_INICIO_ADMIN',
    },
    {
      label: 'Boletas',
      icon: 'pi pi-file',
      route: '/dashboard/payment-slips',
      permission: 'VIEW_BOLETAS_ADMIN',
    },
    {
      label: 'Solicitudes',
      icon: 'pi pi-users',
      route: '/dashboard/solicitude',
      permission: 'VIEW_SOLICITUDES_ADMIN',
    },
    {
      label: 'Ticket y Vales',
      icon: 'pi pi-ticket',
      route: '/dashboard/tickets',
      permission: 'VIEW_TICKETS_ADMIN',
    },
    {
      label: 'Vacaciones',
      icon: 'pi pi-sun',
      route: '/dashboard/vacation',
      permission: 'VIEW_INICIO_ADMIN',
    },
  ];

  filteredMenuOptions: any[] = [];

  constructor(
    private router: Router,
    private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
      this.getName();
    }

    this.loadPermissions();
    this.filterMenuOptions();

    const date = new Date();

    // Array con los meses en español
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    // Construir la fecha en el formato deseado
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Formato: "Diciembre 18 del 2024"
    this.currentDate = `${month} ${day} del ${year}`;
  }

  loadPermissions(): void {
    const storedAccess = localStorage.getItem('access');
    if (storedAccess) {
      const parsedAccess = JSON.parse(storedAccess);
      this.permissions = parsedAccess.permissions || [];
    }
  }

  filterMenuOptions(): void {
    this.filteredMenuOptions = this.menuOptions.filter((option) =>
      this.permissions.includes(option.permission)
    );
  }

  getName(): void {
    this.employeeService.getEmployeeById(this.userData.employeeId).subscribe(
      (employee) => {
        if (employee) {
          this.employeeData = employee;
          this.fullname = employee.names.split(' ')[0] + ' ' + employee.lastNames.split(' ')[0];
        } else {
          console.log('No se encontró el empleado');
        }
      },
      (error) => {
        console.error('Error al obtener el empleado:', error);
      }
    );
  }
  

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
