<div class="header">
  <div class="actions">
    <!-- Botón -->
    <ng-container *ngIf="hasButton">
      <button
      mat-raised-button
      [ngStyle]="{ 'background-color': buttonColor }"
      (click)="onButtonClick()">
      <i [class]="buttonIcon"></i> {{ buttonText }}
    </button>
    </ng-container>
    

    <!-- Combo selector -->
    <div class="combo-select">
      <select
        [ngModel]="comboSelected"
        (ngModelChange)="onComboChange($event)">
        <!-- <option value="" disabled [selected]="!comboSelected" >{{ comboPlaceholder }}</option> -->
        <option value="00">Todos</option>
        <option *ngFor="let option of comboOptions" [ngValue]="option">
          {{ option.text }}
        </option>
      </select>
    </div>

    <!-- Barra de búsqueda -->
    <div class="search-bar">
      <i class="pi pi-search search-icon"></i>
      <input
        type="text"
        [placeholder]="searchPlaceholder"
        [ngModel]="searchQuery"
        (ngModelChange)="onSearchInput($event)" />
    </div>
  </div>
</div>
