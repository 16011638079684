import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { CompanyService } from '../../../services/company.service';
import { EmployeeService } from '../../../services/employee.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { VacationService } from '../../services/vacation.service';
import { SolicitudeService } from '../../../solicitude/services/solicitude.service';
import { forkJoin } from 'rxjs';
import { TypeVacationService } from '../../../services/typeVacation.service';
import { StateSolicitudesService } from '../../../services/stateSolicitudes.service';

@Component({
  selector: 'app-vacation-edit-modal',
  templateUrl: './vacation-edit-modal.component.html',
  styleUrls: ['./vacation-edit-modal.component.scss'],
  providers: [DialogService],
})
export class VacationEditModalComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToEdit: any | null = null;
  @Input() id: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() editComplete = new EventEmitter<void>();

  userData: any = null;
  constructor(
    private vacationService: VacationService,
    private solicitudeService: SolicitudeService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private typeVacationService: TypeVacationService,
    private stateSolicitudesService: StateSolicitudesService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  // Propiedades para los combos
  selectedEmpresa: IComboBase | null = null;
  selectedColaborador: IComboBase | null = null;
  selectedOptionKey: number | null = null;
  selectedVacationType: any;
  selectedstatusSolicitude: any;

  previousStatusKey: string | null = null;

  isLoading: boolean = false;
  detailSolicitudId: number = 0;

  startDate: Date | null = null;
  endDate: Date | null = null;
  minDate: Date | null = null;
  maxStartDate: Date | null = null;
  maxEndDate: Date | null = null;
  tooltipVisible: string | null = null;

  calculateAccumulatedDays: number = 10;

  filteredVacationTypes: IComboBase[] = [];
  totalDays: number = 0;

  transferableDays = Math.max(
    0,
    this.calculateAccumulatedDays - this.totalDays
  );

  selectedDates: Date[] = [];
  message = '';

  filteredOptions: IComboBase[] = [];
  empresas: IComboBase[] = [];
  colaboradores: IComboBase[] = [];
  vacationTypes: IComboBase[] = [];
  statusSolicitude: IComboBase[] = [];

  companyId: string | null = null;
  employeeId: number | null = null;

  isDisabled: boolean = true;

  hasInteracted: { [key: string]: boolean } = {
    tipoVacacion: false,
    endDate: false,
    startDate: false,
  };

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }

    this.previousStatusKey = this.selectedstatusSolicitude?.key;


    this.resetForm();
    this.calculateTotalDays();

    this.typeVacationService.comboTypeVacation().subscribe({
      next: (data) => {
        this.vacationTypes = data;
      },

      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });

    this.stateSolicitudesService.comboStateSolicitudes().subscribe({
      next: (data) => {
        this.statusSolicitude = data;
      },

      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });

    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.empresas = data;
      },

      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });
  }

  ngOnChanges(): void {
    this.isLoading = true;
    if (this.dataToEdit) {
      this.detailSolicitudId = this.dataToEdit.detailSolicitudId;

      this.solicitudeService
        .getDetailSolicitudeByDetailSolicitudeId(this.detailSolicitudId)
        .subscribe({
          next: (solicitude) => {
            const observables = {
              empresas: this.companyService.comboCompany(),
              colaboradores: this.employeeService.comboEmployee(
                solicitude.companyId
              ),
            };

            forkJoin(observables).subscribe({
              next: ({ empresas, colaboradores }) => {
                this.empresas = empresas;
                this.colaboradores = colaboradores;

                this.selectedEmpresa =
                  this.empresas.find(
                    (e) => String(e.key) === String(solicitude.companyId)
                  ) || null;

                this.selectedColaborador =
                  this.colaboradores.find(
                    (c) => String(c.key) === String(solicitude.employeeId)
                  ) || null;

                this.startDate = solicitude.breakStartDate
                  ? this.parseCustomDate(solicitude.breakStartDate)
                  : null;

                this.endDate = solicitude.breakEndDate
                  ? this.parseCustomDate(solicitude.breakEndDate)
                  : null;

                  this.syncSelectedDates();
                  
                this.selectedVacationType =
                  this.vacationTypes.find(
                    (e) => String(e.key) === String(solicitude.typeVacationId)
                  ) || null;

                this.selectedOptionKey = this.selectedVacationType.key!;

                this.message = solicitude.detailSolicitudeDescription
                  ? solicitude.detailSolicitudeDescription
                  : '';

                this.selectedstatusSolicitude =
                  this.statusSolicitude.find(
                    (e) =>
                      String(e.key) === String(solicitude.stateSolicitudeId)
                  ) || null;

                this.isLoading = false;
                this.validateForm();
                this.calculateTotalDays();
                this.cdr.detectChanges();
              },
              error: (err) => {
                this.isLoading = false;
                console.error('Error al cargar los datos necesarios:', err);
              },
            });
          },
          error: (err) => {
            this.isLoading = false;
            console.error(
              'Error al obtener los detalles de la solicitud: ',
              err
            );
          },
        });
    }
  }

  validateForm() {
    this.isDisabled = !(
      this.selectedEmpresa &&
      this.selectedColaborador &&
      this.startDate &&
      this.endDate &&
      this.selectedVacationType
    );
  }

  parseCustomDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  }

  // Método para filtrar opciones
  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'empresa':
        options = this.empresas;
        break;
      case 'colaborador':
        options = this.colaboradores;
        break;
      case 'status':
        options = this.statusSolicitude;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    this.hasInteracted[type] = true;

    switch (type) {
      case 'empresa':
        this.selectedEmpresa = event.value ?? null;
        this.companyId = this.selectedEmpresa?.key!;
        this.selectedColaborador = null;

        if (this.companyId) {
          this.employeeService.comboEmployee(this.companyId).subscribe({
            next: (data) => {
              this.colaboradores = data;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }

        break;
      case 'colaborador':
        this.selectedColaborador = event.value ?? null;
        this.employeeId = Number(this.selectedColaborador?.key!);
        if (this.employeeId) {
          this.employeeService.getEmployeeById(this.employeeId).subscribe({
            next: (data) => {
              this.calculateAccumulatedDays = data.accumulatedDays;
            },
            error: (err) => {
              console.error('Error al obtener la lista de colaboradores:', err);
            },
          });
        }

        break;
      case 'tipoVacacion':
        this.selectedVacationType = event.value ?? null;
        this.selectedOptionKey = this.selectedVacationType?.key || null;
        break;
      case 'status':
        this.selectedstatusSolicitude = event.value ?? null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }

    this.validateForm();
  }

  calculateTotalDays(): void {
    if (this.startDate && this.endDate) {
      const start = this.startDate;
      const end = this.endDate;

      const diffInDays =
        (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1;

      this.totalDays = diffInDays > 0 ? Math.round(diffInDays) : 0;
    } else {
      this.totalDays = 0;
    }

    this.transferableDays = Math.max(
      0,
      this.calculateAccumulatedDays - this.totalDays
    );
  }

  onEdit() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });
  
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const solicitudeCode = `VACATION-${randomNumber}`;
  
    const request = {
      empresaId: this.selectedEmpresa?.key,
      colaboradorId: this.selectedColaborador?.key,
      typeVacationId: this.selectedVacationType?.key,
      solicitudeId: this.id,
      solicitudeCode: solicitudeCode,
      detailSolicitudeId: this.detailSolicitudId.toString(),
      startDate: this.startDate?.toISOString().replace('Z', ''),
      endDate: this.endDate?.toISOString().replace('Z', ''),
      detailSolicitudeMessage: '',
      detailSolicitudeDescription: this.message
        ? this.message
        : 'Se ha realizado una solicitud de vacaciones, por favor, revisarlo y confirmar.',
    };
  
    const formData = new FormData();
    formData.append('companyId', request.empresaId!);
    formData.append('employeeId', request.colaboradorId!);
    formData.append('solicitudeCode', request.solicitudeCode);
    formData.append('typeVacationId', request.typeVacationId!);
    formData.append('breakStartDate', request.startDate!);
    formData.append('breakEndDate', request.endDate!);
    formData.append('detailSolicitudeMessage', request.detailSolicitudeMessage!);
    formData.append('detailSolicitudeDescription', request.detailSolicitudeDescription!);
    formData.append('solicitudeId', request.solicitudeId!);
    formData.append('detailSolicitudeId', request.detailSolicitudeId!);
  
    // Verifica si el estado ha cambiado
    console.log('Estado previo:', this.previousStatusKey);
    console.log('Estado actual:', this.selectedstatusSolicitude?.key);
  
    const updateState =
      this.previousStatusKey !== this.selectedstatusSolicitude?.key;
  
    if (!updateState) {
      console.log('El estado no ha cambiado, no se llamará a changeStateSolicitude');
    }
  
    const updateVacation$ = this.vacationService.updateVacation(formData);
    const updateState$ = updateState
      ? this.solicitudeService.postUpdateStateSolicitudes({
          solicitudeId: this.id,
          stateSolicitudeId: this.selectedstatusSolicitude.key,
          userAdminId: this.userData.employeeId,
          detailSolicitudeMessage: request.detailSolicitudeMessage,
        })
      : null;
  
    const requests = [updateVacation$, ...(updateState$ ? [updateState$] : [])];
  
    Promise.all(
      requests.map((req) =>
        req.toPromise().catch((error) => {
          console.error('Error en una solicitud:', error);
          return error;
        })
      )
    ).then((responses) => {
      const errors = responses.filter((res) => res instanceof Error);
      loadingRef.close();
  
      if (errors.length === 0) {
        const ref = this.dialogService.open(ModalSuccessComponent, {
          header: '',
          data: {
            text: 'Tu solicitud ha sido realizada con éxito.',
            title: '¡Éxito!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.editComplete.emit();
  
        // Actualiza el estado previo después de completar la operación
        this.previousStatusKey = this.selectedstatusSolicitude?.key;
      } else {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          closable: true,
          data: {
            text: 'Algunas solicitudes fallaron. Por favor, inténtalo nuevamente.',
            title: '¡Error!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
      }
      this.closeDialog();
    });
  }
  
  

  onCancel() {
    this.resetForm();
    this.closeDialog();
  }

  // Limpiar los campos del modal
  resetForm() {
    this.selectedEmpresa = null;
    this.selectedColaborador = null;
    this.selectedVacationType = null;
    this.startDate = null;
    this.endDate = null;
    this.message = '';
    this.selectedDates = [];

    this.hasInteracted = {
      empresa: false,
      colaborador: false,
      tipoVacacion: false,
      endDate: false,
      startDate: false,
    };

    // this.calculateTotalDays();
    // this.syncSelectedDates();
    this.validateForm();
  }

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.resetForm();
  }

  onChangeInput(event: any, type: string) {
    this.hasInteracted[type] = true;
    switch (type) {
      case 'empresa':
        this.selectedEmpresa = null;
        break;
      case 'colaborador':
        this.selectedColaborador = null;
        break;
      case 'tipoVacacion':
        this.selectedVacationType = null;
        break;
      case 'status':
        this.selectedstatusSolicitude = null;
        break;
      case 'startDate':
        this.startDate = event;
        break;
      case 'endDate':
        this.endDate = event;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.validateForm();
  }

  validateField(field: string) {
    this.hasInteracted[field] = true;
  }

  filterVacationTypes(event: any): void {
    const query = event.query.toLowerCase();
    this.filteredVacationTypes = this.vacationTypes.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  openTooltipModal(title: string, description: string) {
    this.dialogService.open(ModalInfoComponent, {
      closable: false,
      data: {
        title: title,
        description: description,
      },
    });
  }

  onStartDateChange(selectedStartDate: Date): void {
    if (selectedStartDate) {
      this.startDate = selectedStartDate;
      this.maxEndDate = new Date(selectedStartDate);
      this.maxEndDate.setDate(this.maxEndDate.getDate() + 29);

      if (this.endDate && this.endDate < this.startDate) {
        this.endDate = null;
      }

      this.syncSelectedDates();
      this.calculateTotalDays();
    } else {
      this.maxEndDate = null;
    }
  }

  onEndDateChange(selectedEndDate: Date): void {
    if (selectedEndDate) {
      if (this.startDate && selectedEndDate < this.startDate) {
        this.endDate = null;
      } else {
        this.endDate = selectedEndDate;
      }

      this.syncSelectedDates();
      this.calculateTotalDays();
    }
  }

  onRangeSelect(): void {
    if (Array.isArray(this.selectedDates) && this.selectedDates.length === 2) {
      this.startDate = this.selectedDates[0];
      this.endDate = this.selectedDates[1];

      console.log('this.startDate on range ', this.startDate);
      console.log('this.endDate on range ', this.endDate);
      if (this.startDate) {
        this.maxEndDate = new Date(this.startDate);
        this.maxEndDate.setDate(this.maxEndDate.getDate() + 30);
      } else {
        this.maxEndDate = null;
      }
    } else {
      this.startDate = this.endDate = null;
    }
    // this.calculateTotalDays();
  }

  syncSelectedDates(): void {
    // if (this.startDate && this.endDate) {
    //   this.selectedDates = [this.startDate, this.endDate];
    // } else if (
    //   Array.isArray(this.selectedDates) &&
    //   this.selectedDates.length === 2
    // ) {
    //   this.startDate = this.selectedDates[0];
    //   this.endDate = this.selectedDates[1];
    // } else {
    //   this.selectedDates = [];
    //   this.startDate = null;
    //   this.endDate = null;
    // }
    if (this.startDate && this.endDate) {
      this.selectedDates = [this.startDate, this.endDate];
    } else {
      this.selectedDates = [];
    }
  }

  getDayLabel(days: number): string {
    return days === 1 ? 'día' : 'días';
  }
}
