<div class="login-box">
  <mat-icon class="user-icon">person</mat-icon>
  <h1 class="login-heading">Iniciar sesión</h1>
  <p class="login-description">
    Inicia sesión y administra tus solicitudes como administrador
  </p>

  <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onLogin()">
    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Email</mat-label>
      <input
        matInput
        id="email"
        formControlName="email"
        placeholder="Ingrese su usuario"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-field">
      <mat-label>Contraseña</mat-label>
      <input
        matInput
        id="password"
        formControlName="password"
        type="password"
        placeholder="Ingrese su contraseña"
      />
    </mat-form-field>

    <div class="form-actions">
      <div>
        <input type="checkbox" />
        <a>Recordarme</a>
      </div>
      <a class="forgot-password" href="#">Olvidé mi contraseña</a>
    </div>
    <button
      mat-raised-button
      style="background-color: #ffa600; color: white"
      class="submit-button"
      [disabled]="loginForm.invalid || isSubmitting"
      [ngClass]="{ disabled: loginForm.invalid || isSubmitting }"
    >
      <ng-container *ngIf="isLoading$ | async">
        <span class="indicator-progress">
          Por favor espere...
          <p-progressSpinner
            [style]="{ width: '1.5rem', height: '1.5rem'}"
            animationDuration="1.5s"
            aria-label="Cargando"
          ></p-progressSpinner>
        </span>
      </ng-container>
      <ng-container *ngIf="!(isLoading$ | async)">
        <span class="indicator-label">INGRESAR</span>
      </ng-container>
    </button>

    <!-- <button mat-raised-button style="background-color: #ffa600; color: white;" class="submit-button">
      INGRESAR
    </button> -->
  </form>
</div>
