import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, shareReplay, tap } from 'rxjs';
import { IComboBase } from '../interfaces/base/base.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private apiUrl = `${environment.apiUrl}/employees`;

  private employeeDataCache: any = null; // Caché para los datos del empleado

  private employeeDataSource = new BehaviorSubject<any>(null);
  employeeData$ = this.employeeDataSource.asObservable();

  constructor(private http: HttpClient) {}

  comboEmployee(companyId: string): Observable<IComboBase[]> {
    return this.http.get<IComboBase[]>(`${this.apiUrl}/combo`, {
      params: { companyId: companyId },
    });
  }

  getEmployeeById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`).pipe(
      tap((data) => {
        this.employeeDataSource.next(data); // Actualizar el BehaviorSubject
      })
      // shareReplay(1)
    );
  }

  // Método para establecer los datos del empleado
  setEmployeeData(data: any): void {
    this.employeeDataCache = data; // Guardar en caché
    this.employeeDataSource.next(data); // Actualizar el BehaviorSubject
  }

  clearCache(): void {
    this.employeeDataCache = null;
  }
}
