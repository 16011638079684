import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SolicitudeService } from '../../services/solicitude.service';
import { ChangeStateSolicitudeInterface } from '../../../interfaces/solicitude.interface';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';

@Component({
  selector: 'app-update-state-modal',
  templateUrl: './update-state-modal.component.html',
  styleUrls: ['./update-state-modal.component.scss'],
  providers: [DialogService],
})
export class UpdateStateModalComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false;
  @Input() dataToView: any | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() updateStateComplete = new EventEmitter<void>();

  showButtons: boolean = true;
  showConfirmation: boolean = true;
  showTextarea: boolean = true;
  primaryButtonLabel: string = 'EVALUAR';
  userData: any = null;

  steps = [
    { label: 'Recibido', key: 1 },
    { label: 'En evaluación', key: 2 },
    { label: 'Aprobado', key: 3 },
    { label: '', key: 4 },
  ];

  get visibleSteps() {
    if (this.status === 'aprobado') {
      return this.steps.slice(0, 4); // Muestra solo los primeros 3 labels
    }
    if (this.status === 'rechazado') {
      return this.steps.slice(0, 4);
      // return [
      //   this.steps[0], // Recibido
      //   this.steps[1], // En evaluación
      //   { label: 'Rechazado', key: 4 }, // Rechazado
      // ];
    }
    return this.steps;
  }

  currentStep = 1;
  status: 'pendiente' | 'aprobado' | 'rechazado' = 'pendiente';

  solicitude = {
    stateSolicitudeId: 1,
    stateSolicitudeName: 'En evaluación',
  };

  isLoading: boolean = false;
  dateStart: Date = new Date();
  dateEnd: Date = new Date();
  message: string = '';
  collaborator: string = '';
  adminMessage: string = '';
  confirmationChecked = false;

  constructor(
    private solicitudeService: SolicitudeService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {}

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
  }
  ngOnChanges(): void {
    if (!this.dataToView || !this.dataToView.detailSolicitudId) {
      this.isLoading = false;
      return;
    }

    console.log(
      'this.dataToView.detailSolicitudId: ',
      this.dataToView.detailSolicitudId
    );
    this.isLoading = true;

    this.solicitudeService
      .getDetailSolicitudeByDetailSolicitudeId(
        this.dataToView.detailSolicitudId
      )
      .subscribe({
        next: (solicitude: any) => {
          this.solicitude = {
            stateSolicitudeId: solicitude.stateSolicitudeId,
            stateSolicitudeName: solicitude.stateSolicitudeName,
          };
          this.updateState(solicitude.stateSolicitudeId);
          this.dateStart = solicitude.breakStartDate;
          this.dateEnd = solicitude.breakEndDate;
          this.message = solicitude.detailSolicitudeDescription;
          this.collaborator = solicitude.employeeName;
          // if (
          //   solicitude.stateSolicitudeId === 3 ||
          //   solicitude.stateSolicitudeId === 4
          // ) {
            this.adminMessage = solicitude.detailSolicitudeMessage ;
          // } else {
          //   this.adminMessage = '';
          // }
          console.log(
            'solicitude.stateSolicitudeId ' + solicitude.stateSolicitudeId
          );
          this.isLoading = false;
          // this.cdr.detectChanges();
        },
        error: (err) => {
          this.isLoading = false;
          console.error(
            'Error al obtener los detalles del recibo de pago:',
            err
          );
        },
      });
  }

  updateState(stateId: number): void {
    switch (stateId) {
      case 1: // Recibido
      console.log("stateId1 " + stateId);
        this.currentStep = 1;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'EVALUAR';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      case 2: // En evaluación
        this.currentStep = 2;
        this.status = 'pendiente';
        this.primaryButtonLabel = 'APROBAR';
        this.showButtons = true;
        this.showConfirmation = true;
        this.showTextarea = true;
        break;
      case 3: // Aprobado
      case 4: // Rechazado
        this.currentStep = stateId === 3 ? 4 : 3;
        this.status = stateId === 3 ? 'aprobado' : 'rechazado';
        this.showButtons = false;
        this.showConfirmation = false;
        this.showTextarea = false;
        break;
      default:
        console.warn('Estado no manejado:', stateId);
    }
  }

  parseCustomDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  }

  validateForm(): boolean {
    return !(this.adminMessage && this.confirmationChecked);
  }

  onButtonClick() {
    this.closeDialog();
  }

  resetForm() {
    this.adminMessage = '';
    this.confirmationChecked = false;
  }

  onPrimaryButtonClick(): void {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const stateId = this.solicitude.stateSolicitudeId === 1 ? 2 : 3;
    const title =
      this.solicitude.stateSolicitudeId === 1 ? '¡Evaluado!' : '¡Aprobado!';

    const body: ChangeStateSolicitudeInterface = {
      solicitudeId: this.dataToView.solicitudId,
      stateSolicitudeId: stateId,
      userAdminId: this.userData.employeeId,
      detailSolicitudeMessage: this.adminMessage,
    };
    this.solicitudeService.postUpdateStateSolicitudes(body).subscribe({
      next: (response) => {
        loadingRef.close();
        this.solicitude.stateSolicitudeId = stateId;

        const successMessage = response.message;
        const ref = this.dialogService.open(ModalSuccessComponent, {
          closable: true,
          header: '',
          data: {
            text: successMessage,
            title: title,
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.resetForm();
        this.updateStateComplete.emit();
        this.closeDialog();
      },
      error: (err) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          closable: true,
          data: {
            text:
              err.error?.error ||
              'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
            title: '¡Lo sentimos!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.resetForm();
        console.error('Error al actualizar la solicitud:', err);
      },
    });
  }

  onRejectClick(): void {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      header: 'Cargando',
      closable: false,
    });

    const body: ChangeStateSolicitudeInterface = {
      solicitudeId: this.dataToView.solicitudId,
      stateSolicitudeId: 4,
      userAdminId: this.userData.employeeId,
      detailSolicitudeMessage: this.adminMessage,
    };

    this.solicitudeService.postUpdateStateSolicitudes(body).subscribe({
      next: (response) => {
        loadingRef.close();
        console.log('Solicitud rechazada:', response);
        const successMessage = response.message;
        const ref = this.dialogService.open(ModalSuccessComponent, {
          closable: false,
          data: {
            text: successMessage,
            title: '¡Rechazado!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.resetForm();
        this.updateStateComplete.emit();
        this.closeDialog();
      },
      error: (err) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text:
              err.error?.error ||
              'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
            title: '¡Lo sentimos!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        this.resetForm();
        console.error('Error al rechazar la solicitud:', err);
      },
    });
  }
}
